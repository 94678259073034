import './App.css';
import Progress from './components/Progress';

function App() {
  return (
    <div className="App">
      <Progress />
    
    </div>
  );
}

export default App;
